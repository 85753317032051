import React, { useState } from 'react';
import RideBookingForm from './RideBookingForm';
import BookingHistory from './BookingHistory';
import LiveTracking from './LiveTracking';
import RideSharing from './RideSharing';
import PreferredDrivers from './PreferredDrivers';
import './Bookings.css';

function Bookings() {
  const [activeTab, setActiveTab] = useState('book-ride');
  const [selectedRideId, setSelectedRideId] = useState(null);
  const [isSharing, setIsSharing] = useState(false);

  const handleRideBooked = (rideId) => {
    setSelectedRideId(rideId);
    setActiveTab('live-tracking');
  };

  const handleRideSelect = (rideId) => {
    setSelectedRideId(rideId);
    setActiveTab('live-tracking');
  };

  const handleShareRide = () => setIsSharing(true);
  const handleCancelShare = () => setIsSharing(false);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'book-ride':
        return <RideBookingForm onRideBooked={handleRideBooked} />;
      case 'booking-history':
        return <BookingHistory onRideSelect={handleRideSelect} />;
      case 'live-tracking':
        return selectedRideId ? (
          <LiveTracking rideId={selectedRideId} />
        ) : (
          <p>Please select or book a ride before live tracking.</p>
        );
      case 'ride-sharing':
        return (
          <RideSharing
            bookingDetails={{ id: selectedRideId }}
            onShareRide={handleShareRide}
            onCancelShare={handleCancelShare}
          />
        );
      case 'preferred-drivers':
        return <PreferredDrivers />;
      default:
        return <RideBookingForm onRideBooked={handleRideBooked} />;
    }
  };

  return (
    <div className="page-container">
      {/* Desktop Sidebar (Hidden on Mobile) */}
      <nav className="vertical-nav">
        <button onClick={() => setActiveTab('book-ride')} className={activeTab === 'book-ride' ? 'active' : ''}>
          Book Ride
        </button>
        <button onClick={() => setActiveTab('booking-history')} className={activeTab === 'booking-history' ? 'active' : ''}>
          Booking History
        </button>
        <button onClick={() => setActiveTab('live-tracking')} className={activeTab === 'live-tracking' ? 'active' : ''} disabled={!selectedRideId}>
          Live Tracking
        </button>
        <button onClick={() => setActiveTab('ride-sharing')} className={activeTab === 'ride-sharing' ? 'active' : ''} disabled={!selectedRideId || isSharing}>
          Ride Sharing
        </button>
        <button onClick={() => setActiveTab('preferred-drivers')} className={activeTab === 'preferred-drivers' ? 'active' : ''}>
          Preferred Drivers
        </button>
      </nav>

      {/* Main Content */}
      <div className="tab-content">{renderTabContent()}</div>

      {/* Mobile Bottom Navigation (Now Includes Sidebar Items) */}
      <nav className="bottom-nav">
        <button onClick={() => setActiveTab('book-ride')} className={activeTab === 'book-ride' ? 'active' : ''}>
          Book
        </button>
        <button onClick={() => setActiveTab('booking-history')} className={activeTab === 'booking-history' ? 'active' : ''}>
          History
        </button>
        <button onClick={() => setActiveTab('live-tracking')} className={activeTab === 'live-tracking' ? 'active' : ''} disabled={!selectedRideId}>
          Track
        </button>
        <button onClick={() => setActiveTab('ride-sharing')} className={activeTab === 'ride-sharing' ? 'active' : ''} disabled={!selectedRideId || isSharing}>
          Share
        </button>
        <button onClick={() => setActiveTab('preferred-drivers')} className={activeTab === 'preferred-drivers' ? 'active' : ''}>
          Drivers
        </button>
      </nav>
    </div>
  );
}

export default Bookings;
