import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './DriverRegistrationForm.css';

const DriverRegistrationForm = ({ onRegister }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRegisterDriver = async () => {
    if (!name || !email || !licenseNumber || !phoneNumber || !vehicleModel || !vehicleNumber || !password || !confirmPassword) {
      toast.error('Please fill in all fields');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const driverData = {
            name,
            email,
            licenseNumber,
            phoneNumber,
            vehicleModel,
            vehicleNumber,
            password,
            latitude,
            longitude,
          };

          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register-driver`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(driverData),
            });

            if (response.ok) {
              toast.success(`Driver registered successfully!`);
              onRegister();
              setName('');
              setEmail('');
              setLicenseNumber('');
              setPhoneNumber('');
              setVehicleModel('');
              setVehicleNumber('');
              setPassword('');
              setConfirmPassword('');
            } else {
              toast.error('Failed to register driver');
            }
          } catch (error) {
            toast.error(`Error registering driver: ${error.message}`);
          } finally {
            setLoading(false);
          }
        },
        (error) => {
          toast.error(`Error getting location: ${error.message}`);
          setLoading(false);
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <div className="driver-registration-container">
      <div className="driver-registration-box">
        <h2>Register New Driver</h2>
        <form>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Driver Name" className="form-control" />
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="form-control" />
          <input type="text" value={licenseNumber} onChange={(e) => setLicenseNumber(e.target.value)} placeholder="License Number" className="form-control" />
          <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" className="form-control" />
          <input type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} placeholder="Vehicle Model" className="form-control" />
          <input type="text" value={vehicleNumber} onChange={(e) => setVehicleNumber(e.target.value)} placeholder="Vehicle Number" className="form-control" />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="form-control" />
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" className="form-control" />
          <button type="button" onClick={handleRegisterDriver} disabled={loading} className="submit-btn">
            {loading ? 'Registering...' : 'Register Driver'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DriverRegistrationForm;
