import React from 'react';
import ScheduledRides from './ScheduledRides';
import HealthAndWellness from './HealthAndWellness';
import CommunityPointsOfInterest from './CommunityPointsOfInterest';
import './InnovativeFeatures.css';  // Import the CSS file

const InnovativeFeatures = () => {
  return (
      <div className="features-grid">
            <ScheduledRides />
            <HealthAndWellness />
            <CommunityPointsOfInterest />
      </div>
  );
};

export default InnovativeFeatures;
