import React from 'react';
import { FiEye, FiVolume2, FiType, FiDownload, FiCheckCircle, FiHeadphones, FiGlobe } from 'react-icons/fi';
import '../styles/Accessibility.css';

const Accessibility = () => {
  return (
      <main className="content-section">
        {/* Page Header */}
        <header className="page-header">
          <h1>Making Mobility Inclusive for All</h1>
          <p>We believe in equal access to transportation for everyone, regardless of ability.</p>
        </header>

        <div className="page-content">
          {/* Accessibility Features Section */}
          <section className="accessibility-features">
            <h2 className="section-title">Our Accessibility Features</h2>

            <div className="features-grid">
              <div className="feature-card">
                <h3>Wheelchair Accessible Vehicles</h3>
                <p>Equipped with ramps & space for mobility devices.</p>
                <p><strong>Available:</strong> 24/7 in metro cities.</p>
              </div>

              <div className="feature-card">
                <FiEye className="feature-icon" />
                <h3>Vision Assistance</h3>
                <p>High-contrast mode & screen reader-friendly UI.</p>
                <p><strong>Support:</strong> Text resizing & color adjustments.</p>
              </div>

              <div className="feature-card">
                <FiHeadphones className="feature-icon" />
                <h3>Hearing & Speech Support</h3>
                <p>In-app visual alerts & chat-based driver communication.</p>
                <p><strong>Assistive Service:</strong> Sign language support on request.</p>
              </div>

              <div className="feature-card">
                <FiType className="feature-icon" />
                <h3>Text-to-Speech</h3>
                <p>Convert on-screen text into spoken words.</p>
                <p><strong>Works on:</strong> Android, iOS, and Web.</p>
              </div>

              <div className="feature-card">
                <FiVolume2 className="feature-icon" />
                <h3>Voice Commands</h3>
                <p>Book rides hands-free using our AI voice assistant.</p>
                <p><strong>Integrated with:</strong> Google Assistant & Alexa.</p>
              </div>

              <div className="feature-card">
                <FiGlobe className="feature-icon" />
                <h3>Multi-Language Support</h3>
                <p>Ride booking & customer support in regional languages.</p>
                <p><strong>Available in:</strong> English, Hindi, Tamil, Kannada, and more.</p>
              </div>
            </div>
          </section>

          {/* Compliance Standards Section */}
          <section className="accessibility-standards">
            <h2 className="section-title">Compliance & Standards</h2>
            <p>We adhere to globally recognized accessibility laws:</p>
            <ul className="compliance-list">
              <li>✔ WCAG 2.1 AA Compliant</li>
              <li>✔ RPwD Act 2016 (India)</li>
              <li>✔ ADA Title III Compliant</li>
              <li>✔ EN 301 549 (EU Accessibility Standard)</li>
            </ul>
          </section>
        </div>
      </main>
  );
};

export default Accessibility;
