import React, { useState, useEffect } from 'react';
import Profile from './Profile';
import ContrastMode from '../ContrastMode';
import LanguageSwitcher from '../LanguageSwitcher';
import { FaBars } from 'react-icons/fa'; // Mobile menu icon
import './ProfileSettings.css';

function ProfileSettings() {
  const [activeTab, setActiveTab] = useState('profile');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarVisible, setSidebarVisible] = useState(window.innerWidth > 768); // Show sidebar on desktop

  // Update state on window resize
  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth <= 768;
      setIsMobile(mobileView);
      setSidebarVisible(!mobileView); // Show sidebar if desktop, hide if mobile
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <Profile />;
      case 'preferences':
        return (
          <div>
            <ContrastMode />
            <LanguageSwitcher />
          </div>
        );
      default:
        return <Profile />;
    }
  };

  return (
    <div className="profile-settings-page">


      {/* Sidebar Navigation */}
      <nav className={`vertical-nav ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <button className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>
          Profile
        </button>
        <button className={activeTab === 'preferences' ? 'active' : ''} onClick={() => setActiveTab('preferences')}>
          Preferences
        </button>
      </nav>

      {/* Main Content */}
      <div className={`tab-content ${sidebarVisible ? 'with-sidebar' : 'full-width'}`}>
        {renderTabContent()}
      </div>

      {/* Bottom Navigation for Mobile */}
      {isMobile && (
        <nav className="bottom-nav">
          <button className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>
            Profile
          </button>
          <button className={activeTab === 'preferences' ? 'active' : ''} onClick={() => setActiveTab('preferences')}>
            Preference
          </button>
        </nav>
      )}
    </div>
  );
}

export default ProfileSettings;
