import React, { useState, useEffect } from 'react';
import AdvancedFeatures from './AdvancedFeatures';
import InnovativeFeatures from './InnovativeFeatures';
import UniqueFeatures from './UniqueFeatures';
import { FaBars } from 'react-icons/fa'; // Mobile menu icon
import './FeaturesHub.css';

function FeaturesHub() {
  const [activeTab, setActiveTab] = useState('advanced-features');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarVisible, setSidebarVisible] = useState(!isMobile);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setSidebarVisible(true); // Show sidebar on desktop
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'advanced-features':
        return <AdvancedFeatures />;
      case 'innovative-features':
        return <InnovativeFeatures />;
      case 'unique-features':
        return <UniqueFeatures />;
      default:
        return <AdvancedFeatures />;
    }
  };

  return (
    <div className={`page-container ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      {/* Sidebar Toggle for Mobile */}


      {/* Sidebar Navigation (Hidden on Mobile) */}
      <nav className={`vertical-nav ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <button className={activeTab === 'advanced-features' ? 'active' : ''} onClick={() => setActiveTab('advanced-features')}>
          Future Technologies
        </button>
        <button className={activeTab === 'innovative-features' ? 'active' : ''} onClick={() => setActiveTab('innovative-features')}>
          Innovative Services
        </button>
        <button className={activeTab === 'unique-features' ? 'active' : ''} onClick={() => setActiveTab('unique-features')}>
          Exclusive Offerings
        </button>
      </nav>

      {/* Main Content */}
      <div className={`tab-content ${sidebarVisible ? 'with-sidebar' : 'full-width'}`}>
        {renderTabContent()}
      </div>

      {/* Bottom Navigation (Mobile) */}
      {isMobile && (
        <nav className="bottom-nav">
          <button className={activeTab === 'advanced-features' ? 'active' : ''} onClick={() => setActiveTab('advanced-features')}>
            NextGen
          </button>
          <button className={activeTab === 'innovative-features' ? 'active' : ''} onClick={() => setActiveTab('innovative-features')}>
            Innovation
          </button>
          <button className={activeTab === 'unique-features' ? 'active' : ''} onClick={() => setActiveTab('unique-features')}>
            Exclusive
          </button>
        </nav>
      )}
    </div>
  );
}

export default FeaturesHub;
