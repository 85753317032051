import React from 'react';
import PersonalizedMusicExperience from './PersonalizedMusicExperience';
import LocalTourGuideOption from './LocalTourGuideOption';
import DynamicPricing from './DynamicPricing';
import VirtualDriverAssistant from './VirtualDriverAssistant';
import MoodBasedRideExperience from './MoodBasedRideExperience';
import IntegratedMultiModalTransportation from './IntegratedMultiModalTransportation';
import './AdvancedFeatures.css';  // Import the CSS file

const AdvancedFeatures = () => {
  return (
      <div className="features-grid">
            <PersonalizedMusicExperience />
            <LocalTourGuideOption />
            <DynamicPricing />
            <VirtualDriverAssistant />
            <MoodBasedRideExperience />
            <IntegratedMultiModalTransportation />
      </div>
  );
};

export default AdvancedFeatures;
