import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/Alert';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { Button } from '../ui/Button';
import './LexChat.css';

// Create SpeechRecognition instance if supported
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const speechSynthesis = window.speechSynthesis;

const LexChat = () => {
  const [response, setResponse] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  const idleTimeoutRef = useRef(null);
  const recognition = useRef(SpeechRecognition ? new SpeechRecognition() : null);

  useEffect(() => {
    if (!SpeechRecognition) {
      setError('Speech recognition is not supported in this browser.');
      return;
    }

    const recog = recognition.current;
    recog.lang = 'en-US';
    recog.interimResults = false;
    recog.continuous = false;

    recog.onresult = (event) => {
      if (event.results && event.results[0] && event.results[0][0]) {
        const transcript = event.results[0][0].transcript;
        console.log('Transcript:', transcript);
        sendCommandToLex(transcript);
      }
    };

    recog.onend = () => {
      setIsListening(false);
      clearIdleTimeout();
    };

    recog.onerror = (event) => {
      console.error('Recognition error:', event.error);
      setError(`Speech recognition error: ${event.error}`);
    };

    return () => {
      try {
        recog.stop();
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  const startListening = () => {
    if (!recognition.current) return;
    setError('');
    setIsListening(true);
    try {
      recognition.current.start();
      resetIdleTimeout();
    } catch (err) {
      console.error(err);
      setError('Could not start speech recognition.');
      setIsListening(false);
    }
  };

  const stopListening = () => {
    if (!recognition.current) return;
    try {
      recognition.current.stop();
    } catch (err) {
      console.error(err);
    }
    setIsListening(false);
    clearIdleTimeout();
  };

  const sendCommandToLex = async (command) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/lex/interact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: '12345', message: command }),
      });
      const data = await res.json();
      if (!res.ok) throw new Error(data.message || 'Error communicating with Lex');
      setResponse(data.message);
      speakResponse(data.message);
    } catch (err) {
      console.error(err);
      setError('Failed to process command. Please try again.');
      speakResponse('Failed to process command. Please try again.');
    }
  };

  const speakResponse = (text) => {
    if (!speechSynthesis || !text) return;
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
    utterance.onend = () => {
      // Restart listening after the response is spoken
      if (!isListening) startListening();
    };
  };

  const resetIdleTimeout = () => {
    clearIdleTimeout();
    idleTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 30000); // 30 seconds idle timeout
  };

  const clearIdleTimeout = () => {
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
      idleTimeoutRef.current = null;
    }
  };

  return (

      <CardContent className="lexchat-content">
        {error && (
          <Alert variant="destructive" className="lexchat-alert">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="lexchat-button-container">
          <Button
            onClick={startListening}
            disabled={isListening}
            className="lexchat-button"
          >
            {isListening ? (
              <>
                <Mic className="lexchat-icon pulse" />
                <span>Listening...</span>
              </>
            ) : (
              <>
                <MicOff className="lexchat-icon" />
                <span>Start Listening</span>
              </>
            )}
          </Button>
        </div>
        {response && (
          <Alert variant="default" className="lexchat-response">
            <AlertDescription>Response: {response}</AlertDescription>
          </Alert>
        )}
      </CardContent>
  );
};

export default LexChat;
