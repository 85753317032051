import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { bookRide, getBookingHistory, geocodeAddress, calculateDistanceAndDuration } from '../../services/api';
import { useAuth } from '../../context/AuthContext'; // Use the auth context to get the user's role
import './Dashboard.css';
import Profile from '../Profile/Profile';
import RideUpdates from '../Driver/RideUpdates';  // Import RideUpdates for real-time updates for users
import { FiArrowUpRight, FiFilePlus, FiCalendar, FiMapPin } from 'react-icons/fi';

const Dashboard = () => {
  const navigate = useNavigate();
  const { userRole } = useAuth(); // Get the user's role from the auth context

  const [recentRides, setRecentRides] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [bookingForm, setBookingForm] = useState({
    pickupLocation: '',
    dropLocation: '',
    vehicleType: 'Sedan',
  });

  const [earningsData, setEarningsData] = useState({
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Monthly Earnings",
        data: [],
        fill: false,
        borderColor: '#742774'
      }
    ]
  });

  // Custom flag to track if the dashboard data was already fetched
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const userId = localStorage.getItem('userId');

        if (!dataFetched) {
          if (userRole === 'USER') {
            // Fetch booking history only if not previously fetched
            const rideResponse = await getBookingHistory(userId);
            setRecentRides(rideResponse.data.content || []); // Use content array or default to empty array
            //setRecentRides(rideResponse.data);
          } else if (userRole === 'DRIVER') {
            // Load driver documents and earnings only if the role is DRIVER
            await loadDriverDocuments(userId);
            await loadEarningsData();
          }
          setDataFetched(true); // Mark data as fetched to avoid infinite loop
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userRole && !dataFetched) {
      loadDashboardData(); // Ensure it only runs once when userRole is set and data isn't already fetched
    }
  }, [userRole, dataFetched]); // Only run when userRole or dataFetched changes

  const loadDriverDocuments = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/driver_documents/${userId}`);
      const data = await response.json();
      setDocuments(data); // Set the documents data
    } catch (error) {
      console.error('Error fetching driver documents:', error);
    }
  };

  const loadEarningsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/earnings/${localStorage.getItem('userId')}`);
      const data = await response.json();
      setEarningsData({
        labels: data.map(item => item.month),
        datasets: [{
          label: "Monthly Earnings",
          data: data.map(item => item.earnings),
          borderColor: '#742774',
          fill: false
        }]
      });
    } catch (error) {
      console.error('Error loading earnings data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingForm(prev => ({ ...prev, [name]: value }));
  };

  const handleBookRide = async (e) => {
    e.preventDefault();
    try {
      const pickupCoordinates = await geocodeAddress(bookingForm.pickupLocation);
      const dropCoordinates = await geocodeAddress(bookingForm.dropLocation);
      const { distance, duration } = await calculateDistanceAndDuration(pickupCoordinates, dropCoordinates);
      const bookingData = {
        pickupLocation: bookingForm.pickupLocation,
        dropLocation: bookingForm.dropLocation,
        vehicleType: bookingForm.vehicleType,
        distanceInKm: parseFloat(distance),
        durationInMinutes: parseFloat(duration),
        pickupLatitude: pickupCoordinates.latitude,
        pickupLongitude: pickupCoordinates.longitude,
        dropoffLatitude: dropCoordinates.latitude,
        dropoffLongitude: dropCoordinates.longitude,
      };
      const data = await bookRide(bookingData);
      alert('Ride booked successfully! Ride ID: ' + data.rideId);
      navigate('/booking-confirmation', { state: { bookingDetails: data } });
    } catch (error) {
      console.error('Error booking ride:', error);
      alert('Failed to book ride: ' + error.message);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('driver_id', localStorage.getItem('userId'));
    formData.append('document_type', documentType);
    formData.append('expiry_date', expiryDate);

    try {
      const response = await fetch('/upload_document', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Document uploaded successfully!');
        loadDriverDocuments(localStorage.getItem('userId'));
      } else {
        alert('Failed to upload document. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('An error occurred while uploading the document.');
    }
  };

  if (loading) {
      return (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading dashboard...</p>
        </div>
      );
    }

    return (
      <div className="dashboard-container">
        <header className="dashboard-header">
          <h1>Welcome back, {userRole === 'USER' ? 'Customer' : 'Driver'}!</h1>
        </header>

        <div className="dashboard-grid">
          {userRole === 'DRIVER' && (
            <>
              <div className="earnings-chart card">
                <div className="card-header">
                  <FiArrowUpRight className="card-icon" />
                  <h2>Earnings Overview</h2>
                </div>
                <div className="chart-container">
                  <Line data={earningsData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
              </div>

              <div className="driver-documents card">
                <div className="card-header">
                  <FiFilePlus className="card-icon" />
                  <h2>Document Management</h2>
                </div>
                <div className="document-upload">
                  <label className="file-input">
                    <input type="file" onChange={handleFileChange} />
                    <span>Choose File</span>
                  </label>
                  <div className="document-fields">
                    <input
                      type="text"
                      placeholder="Document Type"
                      value={documentType}
                      onChange={(e) => setDocumentType(e.target.value)}
                    />
                    <input
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                    />
                  </div>
                  <button className="primary-btn" onClick={handleUpload}>
                    Upload Document
                  </button>
                </div>
                <div className="document-list">
                  {documents.map((doc, index) => (
                    <div key={index} className="document-item">
                      <div className="doc-info">
                        <span className="doc-type">{doc.document_type}</span>
                        <span className="doc-expiry">Expires: {doc.expiry_date}</span>
                      </div>
                      <button className="doc-action">View</button>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {userRole === 'USER' && (
            <>
              <div className="quick-book card">
                <div className="card-header">
                  <FiMapPin className="card-icon" />
                  <h2>New Ride Request</h2>
                </div>
                <form onSubmit={handleBookRide}>
                  <div className="input-group">
                    <label>Pickup Location</label>
                    <input
                      type="text"
                      name="pickupLocation"
                      value={bookingForm.pickupLocation}
                      onChange={handleInputChange}
                      placeholder="Enter pickup address"
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label>Drop Location</label>
                    <input
                      type="text"
                      name="dropLocation"
                      value={bookingForm.dropLocation}
                      onChange={handleInputChange}
                      placeholder="Enter drop-off address"
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label>Vehicle Type</label>
                    <select
                      name="vehicleType"
                      value={bookingForm.vehicleType}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="Sedan">Sedan</option>
                      <option value="SUV">SUV</option>
                      <option value="Luxury">Luxury</option>
                    </select>
                  </div>
                  <button type="submit" className="primary-btn book-ride-btn">
                    Book Now
                  </button>
                </form>
              </div>

              <div className="ride-history card">
                <div className="card-header">
                  <FiCalendar className="card-icon" />
                  <h2>Recent Activity</h2>
                </div>
                {recentRides.length > 0 ? (
                  <div className="ride-list">
                    {recentRides.slice().reverse().slice(0, 1).map((ride) => (
                      <div key={ride.id} className="ride-item">
                        <div className="ride-meta">
                          <span className="ride-id">#{ride.rideId}</span>
                          <span className={`status-badge ${ride.status.toLowerCase()}`}>
                            {ride.status}
                          </span>
                        </div>
                        <div className="ride-locations">
                          <div className="location">
                            <span className="location-label">Pickup:</span>
                            <span className="location-text">{ride.pickupLocation}</span>
                          </div>
                          <div className="location">
                            <span className="location-label">Dropoff:</span>
                            <span className="location-text">{ride.dropoffLocation}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="empty-state">
                    <p>No recent rides found</p>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="stats card">
            <div className="card-header">
              <h2>Performance Overview</h2>
            </div>
            <div className="stats-grid">
              <div className="stat-item">
                <span className="stat-label">Total Rides</span>
                <span className="stat-value">{recentRides.length}</span>
              </div>
              <div className="stat-item">
                <span className="stat-label">This Month</span>
                <span className="stat-value">
                  {recentRides.filter((ride) => {
                    const rideDate = new Date(ride.bookingTime);
                    const currentMonth = new Date().getMonth();
                    const currentYear = new Date().getFullYear();
                    return rideDate.getMonth() === currentMonth && rideDate.getFullYear() === currentYear;
                  }).length}
                </span>
              </div>
            </div>
          </div>

          <div className="quick-actions card">
            <div className="card-header">
              <h2>Quick Links</h2>
            </div>
            <div className="action-buttons">
              <button className="action-btn" onClick={() => navigate('/profile')}>
                Account Settings
              </button>
              <button className="action-btn" onClick={() => navigate('/support')}>
                Customer Support
              </button>
              <button className="action-btn" onClick={() => navigate('/promotions')}>
                Special Offers
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Dashboard;