import React from 'react';
import PageLayout from '../components/PageLayout';
import '../styles/Careers.css';

const Careers = () => {
  return (
      <div className="careers-container">
        <h1 className="careers-heading">Build the Future of Mobility</h1>

        <section className="engineering-roles">
          <h2 className="section-heading">Engineering Openings</h2>
          <div className="job-listings">
            <div className="job-card">
              <h3>Senior Backend Engineer</h3>
              <div className="job-meta">
                <span> Bangalore | Full-time</span>
                <span> ₹25L - ₹40L</span>
              </div>
              <div className="job-skills">
                <span>GoLang</span>
                <span>PostgreSQL</span>
                <span>Kubernetes</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>

            <div className="job-card">
              <h3>Frontend Engineer</h3>
              <div className="job-meta">
                <span> Remote | Full-time</span>
                <span> ₹18L - ₹30L</span>
              </div>
              <div className="job-skills">
                <span>React</span>
                <span>TypeScript</span>
                <span>Next.js</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>

            <div className="job-card">
              <h3>AI/ML Engineer</h3>
              <div className="job-meta">
                <span> Bangalore | Hybrid</span>
                <span> ₹28L - ₹45L</span>
              </div>
              <div className="job-skills">
                <span>Python</span>
                <span>TensorFlow</span>
                <span>LLMs</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>
          </div>
        </section>

        <section className="employee-benefits">
          <h2 className="section-heading">Why Join TOCXI?</h2>
          <div className="benefits-grid">
            <div className="benefit-card">
              <i className="fas fa-rupee-sign"></i>
              <h3>ESOP Program</h3>
              <p>Stock options for all full-time employees</p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-baby"></i>
              <h3>Parental Leave</h3>
              <p>6 months paid leave for new parents</p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-heartbeat"></i>
              <h3>Health Insurance</h3>
              <p>Comprehensive health coverage for employees & families</p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-laptop-house"></i>
              <h3>Work From Anywhere</h3>
              <p>Flexible remote & hybrid work options</p>
            </div>
          </div>
        </section>

        <section className="hiring-process">
          <h2 className="section-heading">Our Hiring Journey</h2>
          <ol className="hiring-steps">
            <li>💻 Technical Screen (1 hr)</li>
            <li>🛠 System Design Round</li>
            <li>🤝 Cultural Fit Interview</li>
            <li>📜 Offer within 5 Days</li>
          </ol>
        </section>
      </div>
  );
};

export default Careers;
