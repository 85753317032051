import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarkerAlt, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Home({ isLoggedIn }) {
  const navigate = useNavigate();

  const handleBookRide = () => {
    navigate(isLoggedIn ? '/bookings' : '/login');
  };

  return (
    <div className="home-container">
      {/* Background Video */}
      <div className="video-container">
        <video autoPlay loop muted className="home-video">
          <source src="/car1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="home-content">
          <div className="content-card">
            <h1>Welcome to TOCXI</h1>
            <p>Instant rides, anywhere, anytime—experience the convenience of TOCXI.</p>
            <button className="cta-button" onClick={handleBookRide}>
              Book Your Ride
            </button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="features-section">
        <div className="features-grid">
<div className="feature-item">
  <FontAwesomeIcon icon={faClock} />
  <h3>On-Time Rides</h3>
  <p>Arrive on time, every time with TOCXI.</p>
</div>
          <div className="feature-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <h3>Real-Time Tracking</h3>
            <p>Track your ride from anywhere with ease.</p>
          </div>
          <div className="feature-item">
            <FontAwesomeIcon icon={faMoneyBillAlt} />
            <h3>Cash Payments</h3>
            <p>Pay in cash directly to the driver for a hassle-free ride.</p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer-section">
        <div className="footer-content">
          <div className="footer-grid">
            {/* Footer Brand and Social Links */}
            <div className="footer-brand">
              <h3 className="footer-heading">TOCXI</h3>
              <p className="footer-tagline">Redefining Urban Mobility</p>
              <div className="social-links">
                <a href="#" aria-label="Facebook">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="#" aria-label="Twitter">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="#" aria-label="Instagram">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="#" aria-label="LinkedIn">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div className="footer-links-group">
              <h4 className="footer-subheading">Quick Links</h4>
              <ul className="footer-links">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/support">Support</Link></li>
              </ul>
            </div>

            {/* Legal Links */}
            <div className="c">
              <h4 className="footer-subheading">Legal</h4>
              <ul className="footer-links">
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/terms">Terms & Conditions</Link></li>
                <li><Link to="/security">Security</Link></li>
                <li><Link to="/accessibility">Accessibility</Link></li>
              </ul>
            </div>

            {/* Contact Section */}
            <div className="footer-contact">
              <h4 className="footer-subheading">Contact Us</h4>
              <p><i className="fas fa-phone"></i> + (91) 7892504519</p>
              <p><i className="fas fa-envelope"></i> support@tocxi.com</p>
              <p><i className="fas fa-map-marker-alt"></i> 1st Stage, BTM Layout, Bengaluru</p>
            </div>
          </div>

          {/* Footer Bottom Section */}
          <div className="footer-bottom">
            <p className="copyright">© 2024 TOCXI. All rights reserved.</p>
            <div className="app-badges">
              <a href="https://play.google.com/store/apps/details?id=com.tocxi.app" target="_blank" rel="noopener noreferrer">
                <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                     alt="Get it on Google Play" className="store-badge" />
              </a>
              <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                     alt="Download on the App Store" className="store-badge" />
              </a>
            </div>

          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
