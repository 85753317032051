import React, { useState, useEffect } from 'react';
import DriverLocationUpdate from '../Booking/DriverLocationUpdate';
import DriverNotifications from '../Driver/DriverNotifications';
import DriverActions from '../Driver/DriverActions';
import DriverMap from '../Driver/DriverMap';
import './DriverManagement.css';
import { toast } from 'react-toastify';

function DriverManagement() {
  const [isOnline, setIsOnline] = useState(false);
  const [driverId, setDriverId] = useState(null);
  const [rideId, setRideId] = useState(null);
  const [driverLocation, setDriverLocation] = useState(null);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [rideInProgress, setRideInProgress] = useState(false);

  useEffect(() => {
    const storedDriverId = localStorage.getItem('driverId');
    if (storedDriverId) {
      setDriverId(storedDriverId);
    }
  }, []);

  const toggleOnlineStatus = () => {
    if (!rideInProgress) {
      setIsOnline(!isOnline);
      toast.success(`You're now ${!isOnline ? 'online' : 'offline'}`);
    } else {
      toast.warning("Cannot go offline during active ride");
    }
  };

  const handleRideAccepted = (rideRequest) => {
    if (rideRequest.pickupLatitude && rideRequest.pickupLongitude) {
      setRideId(rideRequest.rideId);
      setPickupLocation({
        lat: rideRequest.pickupLatitude,
        lon: rideRequest.pickupLongitude,
      });
      setRideInProgress(true);
      toast.success("Ride accepted! Navigate to pickup location");
    } else {
      toast.error("Invalid ride request received");
    }
  };

  const handleRideCompletion = () => {
    setRideInProgress(false);
    setRideId(null);
    setPickupLocation(null);
    toast.success("Ride completed successfully");
  };

  return (
    <div className="driver-management-container">
      <nav className="tab-menu">
        <button
          className={isOnline ? 'active-tab' : ''}
          onClick={toggleOnlineStatus}
          disabled={rideInProgress}
        >
          {isOnline ? 'Go Offline' : 'Go Online'}
        </button>
      </nav>

      <div className="tab-content">
        {isOnline ? (
          <>
            <div className="status-indicator">
              <div className={`status-dot ${isOnline ? 'online' : 'offline'}`} />
              <span>{rideInProgress ? 'On Trip' : 'Available'}</span>
            </div>

            <DriverLocationUpdate
              driverId={driverId}
              onLocationUpdate={setDriverLocation}
            />

            <DriverNotifications
              driverId={driverId}
              onRideAccepted={handleRideAccepted}
            />

            {pickupLocation && driverLocation && (
              <div className="map-container">
                <DriverMap
                  driverLocation={driverLocation}
                  pickupLocation={pickupLocation}
                />
              </div>
            )}

            {rideId && (
              <DriverActions
                rideId={rideId}
                onRideComplete={handleRideCompletion}
              />
            )}
          </>
        ) : (
          <div className="offline-message">
            <p>Switch to online mode to receive ride requests</p>
            <small>You'll need stable GPS connection to go online</small>
          </div>
        )}
      </div>
    </div>
  );
}

export default DriverManagement;