import React from "react";
import "./Slider.css"; // Ensure you have a CSS file for styling

const Slider = ({ value, onChange, min = 0, max = 100, step = 1 }) => {
  return (
    <div className="slider-container">
      <input
        type="range"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        min={min}
        max={max}
        step={step}
        className="custom-slider"
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-label="Slider Control"
      />
      <span className="slider-value">{value}</span>
    </div>
  );
};

export default Slider;
