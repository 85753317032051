import React, { useEffect } from 'react';
import {
  FiShield, FiInfo, FiMail, FiLock, FiArrowUp,
  FiUsers, FiGlobe, FiDatabase, FiLink, FiChevronRight
} from 'react-icons/fi';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    const handleScroll = () => {
      const progress = document.getElementById('scroll-progress');
      const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (windowScroll / height) * 100;
      if (progress) progress.style.width = scrolled + "%";
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="privacy-container">
      <div className="scroll-progress" id="scroll-progress"></div>

      <header className="privacy-header">
        <h1>TOCXI Privacy Center</h1>
      </header>

      <div className="privacy-content-wrapper">
        {/* Sidebar Navigation */}
        <nav className="privacy-nav">
          <h3>Table of Contents</h3>
          <ul>
            <li><a href="#definitions"><FiInfo /> Definitions</a></li>
            <li><a href="#collection"><FiDatabase /> Collection</a></li>
            <li><a href="#usage"><FiUsers /> Usage</a></li>
            <li><a href="#security"><FiLock /> Security</a></li>
            <li><a href="#rights"><FiShield /> Rights</a></li>
          </ul>
        </nav>

        {/* Main Content */}
        <main className="privacy-main-content">
          <section id="definitions" className="policy-section">
            <h2><FiInfo /> Key Definitions</h2>
            <p>Understanding the terms we use in our privacy policy...</p>
          </section>

          <section id="collection" className="policy-section">
            <h2><FiDatabase /> Data Collection</h2>
            <p>We collect data to provide better service...</p>
          </section>

          <section id="usage" className="policy-section">
            <h2><FiUsers /> Data Usage</h2>
            <p>How we use your data to improve TOCXI...</p>
          </section>

          <section id="security" className="policy-section">
            <h2><FiLock /> Security Measures</h2>
            <p>Ensuring your data stays safe and secure...</p>
          </section>

          <section id="rights" className="policy-section">
            <h2><FiShield /> Your Rights</h2>
            <p>Your rights regarding data collection and usage...</p>
          </section>
        </main>
      </div>

      {/* Scroll to Top Button */}
      <button className="scroll-top-btn" onClick={scrollToTop}>
        <FiArrowUp />
      </button>
    </div>
  );
};

export default PrivacyPolicy;
