import React from 'react';
import PageLayout from '../components/PageLayout';
import '../styles/About.css';

const About = () => {
  return (
      <div className="about-container">
        <h1 className="about-heading">Redefining Urban Mobility Since 2024</h1>

        <section className="stats-section">
          <div className="stat-item">
            <h3>3k+</h3>
            <p>Completed Rides</p>
          </div>
          <div className="stat-item">
            <h3>50+</h3>
            <p>Cities Served</p>
          </div>
          <div className="stat-item">
            <h3>4.9/5</h3>
            <p>Average Rating</p>
          </div>
        </section>

        <section className="leadership-section">
          <h2 className="section-heading">Our Leadership</h2>
          <div className="leadership-grid">
            <div className="leader-card">
              <div className="leader-content">
                <h3>Jay Pratap Singh</h3>
                <p className="position">CEO & Founder</p>
                <p className="bio">
                  Technology leader with <strong>10+ years</strong> of experience in full-stack development
                  and enterprise solution architecture. Founded TOCXI to revolutionize urban mobility
                  through data-driven decision making. Expertise in building scalable cloud platforms
                  for logistics, healthcare, and transportation domains. Passionate about creating
                  AI-enhanced systems that balance operational efficiency with user-centric design.
                </p>
              </div>
            </div>

            <div className="leader-card">
              <div className="leader-content">
                <h3>Sony Yadav</h3>
                <p className="position">Chief Technology Officer</p>
                <p className="bio">
                  Seasoned software architect specializing in distributed systems and machine learning
                  implementations. Leads development of TOCXI's real-time ride-matching engine handling
                  complex urban mobility patterns. Hands-on experience in building secure payment gateways
                  and IoT-integrated fleet management systems. Strong advocate for modular microservices
                  architecture and continuous integration practices.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="eco-section">
          <h2 className="section-heading">Our Green Initiative</h2>
          <div className="eco-metrics">
            <div className="eco-card">
              <div className="eco-icon">
                <i className="fas fa-battery-full" aria-hidden="true"></i>
              </div>
              <div className="eco-text">
                <h3>5,000+ EVs</h3>
                <p>Largest electric fleet in South Asia(expected)</p>
              </div>
            </div>

            <div className="eco-card">
              <div className="eco-icon">
                <i className="fas fa-tree" aria-hidden="true"></i>
              </div>
              <div className="eco-text">
                <h3>120k Tons</h3>
                <p>CO2 reduced annually(expected)</p>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
};

export default About;