import React from 'react';
import '../styles/Security.css';

const Security = () => {
  return (
    <div className="security-container">
      <header className="security-header">
        <h1>🔒 Security Center</h1>
        <p className="security-subtitle">Your Safety is Our Priority</p>
      </header>

      <section className="security-section">
        <div className="security-card">
          <div className="icon-container shield">🛡️</div>
          <h2>Certifications & Standards</h2>
          <div className="security-grid">
            <div className="security-item">
              <h3>Data Encryption</h3>
              <p>AES-256 & TLS 1.3</p>
              <div className="status-badge">Active</div>
            </div>
            <div className="security-item">
              <h3>Compliance</h3>
              <p>GDPR & PCI DSS</p>
              <div className="status-badge">Certified</div>
            </div>
          </div>
        </div>
      </section>

      <section className="driver-vetting">
        <h2>  Driver Verification Process</h2>
        <div className="process-steps">
          <div className="process-step">
            <div className="step-number">1</div>
            <h3>Background Check</h3>
            <ul>
              <li>7-year criminal record</li>
              <li>Driving history review</li>
            </ul>
          </div>
          <div className="process-step">
            <div className="step-number">2</div>
            <h3>Training</h3>
            <ul>
              <li>100-hour program</li>
              <li>Safety protocols</li>
            </ul>
          </div>
          <div className="process-step">
            <div className="step-number">3</div>
            <h3>Monitoring</h3>
            <ul>
              <li>Real-time tracking</li>
              <li>Regular audits</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="emergency-section">
        <div className="emergency-card">
          <div className="icon-container emergency">🚨</div>
          <h2>In-App Safety Features</h2>
          <div className="feature-list">
            <div className="feature-item">
              <h3>SOS Button</h3>
              <p>Instant emergency response</p>
            </div>
            <div className="feature-item">
              <h3>Share Ride</h3>
              <p>Real-time location sharing</p>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
};

export default Security;