// src/components/TermsConditions.js
import React from 'react';
import { FiArrowUp, FiBook, FiDollarSign, FiAlertCircle, FiShield, FiLock, FiGlobe, FiUserCheck } from 'react-icons/fi';
import '../styles/TermsConditions.css';

const TermsConditions = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="legal-container">
      <div className="legal-header">
        <h1><FiBook /> Terms of Service</h1>
        <div className="meta-info">
          <p className="effective-date"><FiAlertCircle /> Effective: January 1, 2024</p>
          <p className="last-updated"><FiUserCheck /> Last Updated: March 15, 2024</p>
        </div>
      </div>

      <div className="legal-content-wrapper">
        <nav className="toc-sidebar">
          <h3>Table of Contents</h3>
          <ul>
            <li>
              <a href="#account" className="toc-link">
                <FiShield className="toc-icon" />
                <span>Account Registration</span>
              </a>
            </li>
            <li>
              <a href="#pricing" className="toc-link">
                <FiDollarSign className="toc-icon" />
                <span>Pricing & Payments</span>
              </a>
            </li>
            <li>
              <a href="#disputes" className="toc-link">
                <FiAlertCircle className="toc-icon" />
                <span>Dispute Resolution</span>
              </a>
            </li>
          </ul>
        </nav>

        <main className="legal-main-content">
          <section id="account" className="policy-section">
            <div className="section-header">
              <div className="section-icon-container">
                <FiShield className="section-icon" />
              </div>
              <h2>1. Account Registration</h2>
            </div>
            <p className="section-intro">To ensure transaction security and regulatory compliance:</p>
            <ul className="styled-list">
              <li>Valid government-issued ID required for bookings above ₹2000</li>
              <li>Payment method must match account holder information</li>
              <li>Users must be 18+ years old to create an account</li>
            </ul>
          </section>

          <section id="pricing" className="policy-section">
            <div className="section-header">
              <div className="section-icon-container">
                <FiDollarSign className="section-icon" />
              </div>
              <h2>2. Pricing & Payments</h2>
            </div>
            <div className="pricing-details">
              <div className="price-card">
                <h3 className="price-card-title">Base Fare Structure</h3>
                <ul className="price-card-list">
                  <li><span className="price-label">Minimum fare:</span> ₹25</li>
                  <li><span className="price-label">First 5km:</span> ₹15/km</li>
                  <li><span className="price-label">Beyond 5km:</span> ₹12/km</li>
                </ul>
              </div>
              <div className="price-card">
                <h3 className="price-card-title">Additional Charges</h3>
                <ul className="price-card-list">
                  <li><span className="price-label">GST:</span> 5% included</li>
                  <li><span className="price-label">Peak hours:</span> +20%</li>
                  <li><span className="price-label">Late night:</span> +15%</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="disputes" className="policy-section">
            <div className="section-header">
              <div className="section-icon-container">
                <FiAlertCircle className="section-icon" />
              </div>
              <h2>3. Dispute Resolution</h2>
            </div>
            <div className="dispute-process">
              <div className="process-step">
                <div className="step-number">1</div>
                <h4 className="step-title">Initial Complaint</h4>
                <p className="step-description">File within 7 days through our support portal</p>
              </div>
              <div className="process-step">
                <div className="step-number">2</div>
                <h4 className="step-title">Investigation</h4>
                <p className="step-description">48-hour response time commitment</p>
              </div>
              <div className="process-step">
                <div className="step-number">3</div>
                <h4 className="step-title">Resolution</h4>
                <p className="step-description">Final determination within 14 business days</p>
              </div>
            </div>
          </section>

          <div className="compliance-section">
            <h3 className="compliance-title">Our Compliance Standards</h3>
            <div className="compliance-badges">
              <div className="compliance-badge">
                <FiLock className="badge-icon" />
                <span>Data Security</span>
              </div>
              <div className="compliance-badge">
                <FiGlobe className="badge-icon" />
                <span>GDPR Ready</span>
              </div>
              <div className="compliance-badge">
                <FiShield className="badge-icon" />
                <span>Consumer Protection</span>
              </div>
            </div>
          </div>
        </main>
      </div>

      <button 
        className="scroll-top-btn" 
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <FiArrowUp />
      </button>
    </div>
  );
};

export default TermsConditions;