import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Bookings from './components/Booking/Bookings';
import DriverManagement from './components/Driver/DriverManagement';
import Dashboard from './components/Dashboard/Dashboard';
import FeaturesHub from './components/Features/FeaturesHub';
import ProfileSettings from './components/Profile/ProfileSettings';
import BookingConfirmation from './components/Booking/BookingConfirmation';
import DriverLoginForm from './components/Driver/DriverLoginForm';
import Login from './components/Authentication/Login';
import Register from './components/Authentication/Register';
import DriverRegistrationForm from './components/Driver/DriverRegistrationForm';
import { useAuth } from './context/AuthContext';  // Import the AuthContext
import './App.css';  // Custom styles
import Profile from './components/Profile/Profile';
import Support from './components/Dashboard/Support';
import Promotions from './components/Dashboard/Promotions';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import DriverActions from './components/Driver/DriverActions';  // Import DriverActions
import LexChat from './components/Booking/LexChat';
import NavBar from './components/NavBar/NavBar';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteAccount from './components/DeleteAccount';


import About from './pages/About';
import Careers from './pages/Careers';
import Blog from './pages/Blog';
import TermsConditions from './pages/TermsConditions';
import Security from './pages/Security';
import Accessibility from './pages/Accessibility';
/*

// Custom Hook for WebSocket handling
const useWebSocket = (driverId, onRideRequest) => {
  const stompClientRef = useRef(null);

  useEffect(() => {
    if (driverId) {
      const socket = new SockJS('http://localhost:8080/ws');
      const stompClient = new Client({
        webSocketFactory: () => socket,
        reconnectDelay: 5000,
        debug: (str) => console.log(str),
      });

      stompClient.onConnect = () => {
        console.log('WebSocket connected');
        stompClient.subscribe(`/topic/driver/${driverId}/ride-requests`, (message) => {
          const rideRequest = JSON.parse(message.body);
          onRideRequest(rideRequest);
        });
      };

      stompClient.onStompError = (frame) => {
        console.error('WebSocket error:', frame.headers['message'], frame.body);
      };

      stompClient.activate();
      stompClientRef.current = stompClient;

      return () => {
        if (stompClientRef.current) {
          stompClientRef.current.deactivate();
        }
      };
    }
  }, [driverId, onRideRequest]);

  return stompClientRef;
};
 */

function App() {
  const { isAuthenticated, logout, userRole } = useAuth();  // Use role from auth
  /* const [isOnline, setIsOnline] = useState(false);  // Track online status
  const [rideRequests, setRideRequests] = useState([]); // Track incoming ride requests
  const [acceptedRide, setAcceptedRide] = useState(null); // Track accepted ride
  const driverId = localStorage.getItem('driverId');  // Assuming driverId is stored in localStorage

  // Use WebSocket hook and receive stompClientRef to publish events
  const stompClientRef = useWebSocket(driverId, (rideRequest) => {
    setRideRequests((prevRequests) => [...prevRequests, rideRequest]);
    console.log('Received new ride request:', rideRequest);
  });

  const toggleOnlineStatus = () => {
    const newStatus = !isOnline;
    setIsOnline(newStatus);

    if (stompClientRef.current) {
      stompClientRef.current.publish({
        destination: `/app/driver/${newStatus ? 'online' : 'offline'}`,
        body: JSON.stringify({ driverId }),
      });
    }

    console.log(`Driver is now ${newStatus ? 'Online' : 'Offline'}`);
  };

  useEffect(() => {
    // Save the online status in localStorage to persist between page refreshes
    localStorage.setItem('isOnline', JSON.stringify(isOnline));
  }, [isOnline]);

  const handleRideAccept = (ride) => {
    setAcceptedRide(ride);  // Store the accepted ride for further action
  };

  const handleRideReject = (rideId) => {
    setRideRequests((prevRequests) => prevRequests.filter((r) => r.rideId !== rideId));
  }; */

  return (
    <Router>
      <div>
        {/* <nav>
          <div className="nav-container">
            <div className="nav-logo">
              <Link to="/">NiceCab</Link>
            </div>
            <ul className="nav-menu">
              {isAuthenticated ? (
                <>
                  {userRole === 'USER' && (
                    <>
                      <li><Link to="/Bookings">Bookings</Link></li>
                      <li><Link to="/features">Features</Link></li>
                      <li><Link to="/dashboard">Dashboard</Link></li>
                      <li><Link to="/settings">Profile & Settings</Link></li>
                      <li><Link to="/LexChat">Chat with Jack</Link></li>


                    </>
                  )}
                  {userRole === 'DRIVER' && (
                    <>
                      <li><Link to="/drivers">Driver Management</Link></li>
                      <li><Link to="/dashboard">Driver Dashboard</Link></li>

                    </>
                  )}
                  <li className="logout">
                    <button className="logout-btn" onClick={logout}>Logout</button>
                  </li>
                </>
              ) : (
                <>
                  <li><Link to="/login">Login</Link></li>
                  <li><Link to="/register">Register</Link></li>
                  <li><Link to="/DriverRegistrationForm">Driver Registration</Link></li>
                </>
              )}
            </ul>
          </div>
        </nav> */}
                <NavBar /> {/* Use the NavBar component here */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />  {/* Add Privacy Policy Route */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/DriverLoginForm" element={<DriverLoginForm />} />
          <Route path="/DriverRegistrationForm" element={<DriverRegistrationForm />} />


                  <Route path="/about" element={<About />} />
                  <Route path="/careers" element={<Careers />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsConditions />} />
                  <Route path="/security" element={<Security />} />
                  <Route path="/accessibility" element={<Accessibility />} />
          {isAuthenticated && (
            <>
              {userRole === 'USER' && (
                <>
                  <Route path="/Bookings" element={<Bookings />} />
                  <Route path="/features" element={<FeaturesHub />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/settings" element={<ProfileSettings />} />
                  <Route path="/Profile" element={<Profile />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/promotions" element={<Promotions />} />
                  <Route path="/booking-confirmation" element={<BookingConfirmation />} />
                  <Route path="/LexChat" element={<LexChat />} />
                </>
              )}
              {userRole === 'DRIVER' && (
                <>
                  <Route path="/drivers" element={<DriverManagement />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/Profile" element={<Profile />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/promotions" element={<Promotions />} />


                </>
              )}
            </>
          )}
        </Routes>
{/*          */}{/* Display ride requests */}{/*
        {isOnline && rideRequests.length > 0 && (
          <div className="ride-requests-container">
            <h2>New Ride Requests</h2>
            {rideRequests.map((request, index) => (
              <div key={index} className="ride-request">
                <p>Pickup: {request.pickupLocation}</p>
                <p>Drop: {request.dropoffLocation}</p>
                <button onClick={() => handleRideAccept(request)}>Accept</button>
                <button onClick={() => handleRideReject(request.rideId)}>Reject</button>
              </div>
            ))}
          </div>
        )}
         */}{/* Show DriverActions when a ride is accepted */}{/*
                {acceptedRide && (
                  <div className="driver-actions-container">
                    <h3>Handling Ride ID: {acceptedRide.rideId}</h3>
                    <DriverActions rideId={acceptedRide.rideId} />
                  </div>
                )} */}
      </div>
    </Router>
  );
}

export default App;
