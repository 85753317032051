import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaCar, FaEdit, FaSave, FaCamera } from 'react-icons/fa';
import './Profile.css';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState({
    username: "",
    email: "",
    fullName: "",
    phone: "",
    address: "",
    vehicleModel: "",
    vehiclePlate: "",
    profilePicture: "/icons/taxi.png"
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newProfilePicture, setNewProfilePicture] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProfile(data);
          setLoading(false);
        } else {
          throw new Error('Failed to fetch profile data');
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile data');
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfilePicture(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const formData = new FormData();

      formData.append('username', profile.username);
      formData.append('email', profile.email);
      formData.append('fullName', profile.fullName);
      formData.append('phone', profile.phone);
      formData.append('address', profile.address);
      formData.append('vehicleModel', profile.vehicleModel);
      formData.append('vehiclePlate', profile.vehiclePlate);

      if (newProfilePicture) {
        formData.append('profilePicture', newProfilePicture);
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/update`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const updatedProfile = await response.json();
        setIsEditing(false);
        setProfile(updatedProfile);
      } else {
        throw new Error('Failed to update profile data');
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile data');
    }
  };

  if (loading) return <div className="loading">Loading profile...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="profile-container">
      <h1 className="profile-title">My Profile</h1>
      <div className="profile-content">
        <div className="profile-picture">
          <img src={profile.profilePicture || '/icons/default-profile.png'} alt="Profile" />
          {isEditing && (
            <label className="change-picture-label">
              <FaCamera className="camera-icon" />
              <input type="file" onChange={handleFileUpload} />
            </label>
          )}
        </div>
        <form className="profile-details" onSubmit={handleSubmit}>
          <div className="profile-field">
            <FaUser className="field-icon" />
            <label>Username</label>
            <input type="text" name="username" value={profile.username} disabled />
          </div>
          <div className="profile-field">
            <FaEnvelope className="field-icon" />
            <label>Email</label>
            <input type="email" name="email" value={profile.email} disabled />
          </div>
          <div className="profile-field">
            <FaUser className="field-icon" />
            <label>Full Name</label>
            <input
              type="text"
              name="fullName"
              value={profile.fullName}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaPhone className="field-icon" />
            <label>Phone</label>
            <input
              type="tel"
              name="phone"
              value={profile.phone}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaMapMarkerAlt className="field-icon" />
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={profile.address}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaCar className="field-icon" />
            <label>Vehicle Model</label>
            <input
              type="text"
              name="vehicleModel"
              value={profile.vehicleModel}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-field">
            <FaCar className="field-icon" />
            <label>Vehicle Plate</label>
            <input
              type="text"
              name="vehiclePlate"
              value={profile.vehiclePlate}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="profile-actions">
            {isEditing ? (
              <button type="submit" className="save-btn">
                <FaSave /> Save Changes
              </button>
            ) : (
              <button type="button" className="edit-btn" onClick={() => setIsEditing(true)}>
                <FaEdit /> Edit Profile
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;