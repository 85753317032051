import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './NavBar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const { isAuthenticated, logout, userRole } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // Now it's inside a Router

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect after logout
  };
  return (
    <nav className="navbar">
      <div className="nav-container">
        <div className="nav-logo">
          <Link to="/" className="nav-logo-link">TOCXI</Link>
        </div>

        <div
          className={`nav-menu ${isMobileMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          {isAuthenticated ? (
            <>
              {userRole === 'USER' && (
                <>
                  <Link to="/Bookings" className="nav-link">Bookings</Link>
                  <Link to="/features" className="nav-link">Features</Link>
                  <Link to="/dashboard" className="nav-link">Dashboard</Link>
                  <Link to="/settings" className="nav-link">Profile & Settings</Link>
                </>
              )}
              {userRole === 'DRIVER' && (
                <>
                  <Link to="/drivers" className="nav-link">Driver Management</Link>
                  <Link to="/dashboard" className="nav-link">Driver Dashboard</Link>
                </>
              )}
              <button className="logout-btn" onClick={handleLogout}>Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="nav-link">Login</Link>
              <Link to="/register" className="nav-link">Register</Link>
              <Link to="/DriverRegistrationForm" className="nav-link">Driver Registration</Link>
            </>
          )}
        </div>

        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;