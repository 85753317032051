import React from 'react';
import PageLayout from '../components/PageLayout';
import '../styles/Blog.css';

const Blog = () => {
  return (
      <div className="blog-container">
        <header className="blog-header">
          <h1>TOCXI Insights</h1>
          <p>Stay informed about the latest in urban mobility, technology, and safety.</p>
        </header>

        <nav className="category-filter">
          <button className="active">All</button>
          <button>Technology</button>
          <button>Safety</button>
        </nav>

        <section className="blog-list">
          <article className="blog-post">
            <h2>Scaling Our Electric Fleet to 10,000 Vehicles</h2>
            <div className="post-meta">
              <span>By Sony Yadav, CTO</span>
              <span>March 28, 2024</span>
            </div>
            <p>We are rapidly expanding our electric vehicle fleet and integrating AI-powered routing systems...</p>
            <a href="#" className="read-more">Read More</a>
          </article>

          <article className="blog-post">
            <h2>How TOCXI Ensures Passenger Safety</h2>
            <div className="post-meta">
              <span>By Priya Sharma, Safety Lead</span>
              <span>April 5, 2024</span>
            </div>
            <p>Advanced driver screening, AI-based monitoring, and emergency response ensure passenger security...</p>
            <a href="#" className="read-more">Read More</a>
          </article>

          <article className="blog-post">
            <h2>Revolutionizing Payments in Ride-Hailing</h2>
            <div className="post-meta">
              <span>By Amit Verma, Head of Payments</span>
              <span>April 10, 2024</span>
            </div>
            <p>Seamless digital payments, UPI integration, and fraud prevention strategies for a smooth experience...</p>
            <a href="#" className="read-more">Read More</a>
          </article>
        </section>

        <footer className="newsletter-cta">
          <h3>Get Mobility Insights Weekly</h3>
          <p>Subscribe to receive curated insights and updates on urban mobility.</p>
          <div className="subscribe-form">
            <input type="email" placeholder="Enter your email" />
            <button className="subscribe-button">Subscribe</button>
          </div>
        </footer>
      </div>
  );
};

export default Blog;
