import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMoon, FaSun } from 'react-icons/fa';
import './ContrastMode.css';

const ContrastMode = () => {
  const { t } = useTranslation();

  // Retrieve initial mode from localStorage
  const [highContrast, setHighContrast] = useState(() => {
    return JSON.parse(localStorage.getItem('highContrastMode')) || false;
  });

  useEffect(() => {
    document.body.classList.toggle('high-contrast', highContrast);
    localStorage.setItem('highContrastMode', JSON.stringify(highContrast));
  }, [highContrast]);

  const toggleContrast = () => {
    setHighContrast((prev) => !prev);
  };

  return (
    <div className="contrast-mode-container">
      <button
        className={`contrast-toggle ${highContrast ? 'active' : ''}`}
        onClick={toggleContrast}
      >
        {highContrast ? <FaMoon /> : <FaSun />}
        <span>{t('High Contrast Mode')}</span>
      </button>
    </div>
  );
};

export default ContrastMode;
